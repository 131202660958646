import React from "react";

function News() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2" data-aos="fade-up">
              No Coding or Software Development experience required
            </h2>
          </div>

          {/* Articles list */}
          <div className="max-w-sm mx-auto md:max-w-none">
            <div className="grid gap-12 md:grid-cols-3 md:gap-x-6 md:gap-y-8 items-start">
              {/* 1st article */}
              <article className="flex flex-col h-full" data-aos="fade-up">
                <header>
                  <div className="block mb-6" href="blog-post.html">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img
                        className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out"
                        src={
                          require("../images/totally-add-remove-functionality.jpg")
                            .default
                        }
                        width="352"
                        height="198"
                        alt="News 01"
                      />
                    </figure>
                  </div>
                  <div className="mb-3"></div>
                  <h3 className="h4 mb-2">
                    Add or remove functionality and features as required.
                  </h3>
                </header>
                <p className="text-lg text-gray-400 flex-grow">
                  As businesses evolve over time, associated business processes
                  also change.
                </p>
                <p className="text-lg text-gray-400 flex-grow">
                  A TOTALLY App enables Business Owners themselves to add,
                  remove or tweak functionality to match any business
                  requirement.
                </p>
              </article>

              {/* 2nd article */}
              <article
                className="flex flex-col h-full"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <header>
                  <div className="block mb-6" href="blog-post.html">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img
                        className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out"
                        src={
                          require("../images/square-point-of-sale.jpg").default
                        }
                        width="352"
                        height="198"
                        alt="News 02"
                      />
                    </figure>
                  </div>
                  <div className="mb-3"></div>
                  <h3 className="h4 mb-2">
                    <h3 className="h4 mb-2">
                      Built in connectivity to Point-of-Sale and other essential
                      business software.
                    </h3>
                  </h3>
                </header>
                <p className="text-lg text-gray-400 mb-4 flex-grow">
                  Systems such as Point-of-Sale software and devices are vital
                  to the success of many businesses.
                </p>
                <p className="text-lg text-gray-400 flex-grow">
                  TOTALLY recognises this and provides out-of-the-box
                  integration for popular, often essential, 3rd Party
                  Applications across a wide range of Industries.
                </p>
              </article>

              {/* 3rd article */}
              <article
                className="flex flex-col h-full"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <header>
                  <div className="block mb-6" href="blog-post.html">
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img
                        className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out"
                        src={require("../images/xero-software.jpg").default}
                        width="352"
                        height="198"
                        alt="News 03"
                      />
                    </figure>
                  </div>
                  <div className="mb-3"></div>
                  <h3 className="h4 mb-2">
                    Built in integration with all popular Accounting Software.
                  </h3>
                </header>
                <p className="text-lg text-gray-400 flex-grow">
                  TOTALLY optionally integrates out-of-the-box with Accounting
                  Software including Xero, QuickBooks & MYOB.
                </p>
                <p className="text-lg text-gray-400 flex-grow">
                  The depth of integration is fully configurable within a
                  TOTALLY App without any Coding or Software Developer
                  intervention.
                </p>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default News;
