import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
// import Dropdown from "../utils/Dropdown";

function Header() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const trigger = useRef(null);
  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (
        !mobileNavOpen ||
        mobileNav.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setMobileNavOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <header className="absolute w-full z-30">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-20">
          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <Link
              to="/"
              className="block"
              aria-label="Cruip"
              style={{
                paddingTop: "2rem",
                border: "0px solid red",
              }}
            >
              <img
                className="w-25 h-10"
                src={
                  require("../images/logos/totally-logo-indigo_on_trans.png")
                    .default
                }
                alt="TotallyApp"
              />
            </Link>
          </div>

          {/* <div className="hidden md:flex md:flex-grow h3">
            Business Software...Simplified.
          </div> */}
        </div>
      </div>
    </header>
  );
}

export default Header;
