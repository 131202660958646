import React from "react";

function Target() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2" data-aos="fade-up">
              Connect directly to your Accountant or Bookkeeper
            </h2>
          </div>

          {/* Items */}
          <div className="grid gap-20" data-aos-id-target>
            {/* Item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:rtl"
                data-aos="fade-right"
                data-aos-delay="200"
                data-aos-anchor="[data-aos-id-target]"
              >
                <img
                  className="mx-auto md:max-w-none"
                  src={
                    require("../images/totally-accounting-integ.png").default
                  }
                  width="540"
                  height="520"
                  alt="Features 02"
                />
              </div>

              {/* Content */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6">
                <div className="md:pl-4 lg:pl-12 xl:pl-16">
                  {/* <div
                    className="font-architects-daughter text-xl text-purple-600 mb-2"
                    data-aos="fade-left"
                    data-aos-anchor="[data-aos-id-target]"
                  >
                    Be the change you want to see
                  </div> */}
                  <div
                    className="mt-6"
                    data-aos="fade-left"
                    data-aos-delay="200"
                    data-aos-anchor="[data-aos-id-target]"
                  >
                    <h4 className="h4 mb-2">
                      Simplify Tax and Financial Reporting
                    </h4>
                    <p className="text-lg text-gray-400">
                      Direct connectivity between your TOTALLY App, your
                      Accounting Software and your Accountant or Bookkeeper
                      means that the data required for preparation of Financial
                      Reports and Tax Lodgement is only ever a click away.
                    </p>
                  </div>
                  <div
                    className="mt-6"
                    data-aos="fade-left"
                    data-aos-delay="400"
                    data-aos-anchor="[data-aos-id-target]"
                  >
                    <h4 className="h4 mb-2">Reduce Bookkeeping Expense</h4>
                    <p className="text-lg text-gray-400">
                      Business transaction data only needs to be handled once in
                      your TOTALLY App before it can be exported to your
                      Accounting Software or provided directly to your
                      Accountant or Bookkeeper. Single handing of data means
                      less bookkeeping hours and lower expense.
                    </p>
                  </div>
                  <div
                    className="mt-6"
                    data-aos="fade-left"
                    data-aos-delay="600"
                    data-aos-anchor="[data-aos-id-target]"
                  >
                    <h4 className="h4 mb-2">Unlock Business Improvement</h4>
                    <p className="text-lg text-gray-400">
                      You can't improve what you can't measure. Transaction and
                      other business data is captured live in a TOTALLY App
                      making key business data available when things happen, not
                      months after the event. TOTALLY Apps turn business data
                      into business improvement opportunity.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Target;
