import React from 'react';

function Team() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4">Our passionate team</h2>
            <p className="text-xl text-gray-400">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum consequat.</p>
          </div>

          {/* Team members */}
          <div className="sm:flex sm:flex-wrap sm:justify-center -my-4 sm:-my-8 sm:-mx-3" data-aos-id-team>

            {/* 1st member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-anchor="[data-aos-id-team]">
              <div className="flex flex-col items-center">
                <img className="rounded-full mb-4" src={require('../images/icons/purple-user-icon.png').default} width="120" height="120" alt="Allan Hyde" />
                <h4 className="text-xl font-medium mb-1">Allan Hyde</h4>
                <div className="text-gray-500 mb-1">Managing Director</div>
                <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="mailto: allan@totallyapp.com.au">allan@totallyapp.com.au</a>
              </div>
            </div>

            {/* 2nd member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-delay="100" data-aos-anchor="[data-aos-id-team]">
              <div className="flex flex-col items-center">
                <img className="rounded-full mb-4" src={require('../images/icons/purple-user-icon.png').default} width="120" height="120" alt="Mike Cunneen" />
                <h4 className="text-xl font-medium mb-1">Mike Cunneen</h4>
                <div className="text-gray-500 mb-1">Head of Development</div>
                <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="mailto: mike@totallyapp.com.au">mike@totallyapp.com.au</a>
              </div>
            </div>

            {/* 3rd member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-delay="200" data-aos-anchor="[data-aos-id-team]">
              <div className="flex flex-col items-center">
                <img className="rounded-full mb-4" src={require('../images/icons/purple-user-icon.png').default} width="120" height="120" alt="Team member 03" />
                <h4 className="text-xl font-medium mb-1">xxxxxxx</h4>
                <div className="text-gray-500 mb-1">xxxxxx</div>
                <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="#0">@totallyapp.com.au</a>
              </div>
            </div>

            {/* 4th member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-delay="300" data-aos-anchor="[data-aos-id-team]">
              <div className="flex flex-col items-center">
                <img className="rounded-full mb-4" src={require('../images/icons/purple-user-icon.png').default} width="120" height="120" alt="Team member 04" />
                <h4 className="text-xl font-medium mb-1">xxxxxx</h4>
                <div className="text-gray-500 mb-1">xxxxxxxx</div>
                <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="#0">@totallyapp.com.au</a>
              </div>
            </div>

            {/* 5th member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-delay="400" data-aos-anchor="[data-aos-id-team]">
              <div className="flex flex-col items-center">
                <img className="rounded-full mb-4" src={require('../images/icons/purple-user-icon.png').default} width="120" height="120" alt="Team member 05" />
                <h4 className="text-xl font-medium mb-1">xxxxxx  xxxx</h4>
                <div className="text-gray-500 mb-1">xxxxxxxxxx</div>
                <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="#0">@totallyapp.com.au</a>
              </div>
            </div>

            {/* 6th member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-delay="500" data-aos-anchor="[data-aos-id-team]">
              <div className="flex flex-col items-center">
                <img className="rounded-full mb-4" src={require('../images/icons/purple-user-icon.png').default} width="120" height="120" alt="Team member 06" />
                <h4 className="text-xl font-medium mb-1">Caittlin</h4>
                <div className="text-gray-500 mb-1">React Web Developer</div>
                <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="#0">@totallyapp.com.au</a>
              </div>
            </div>

            {/* 7th member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-delay="600" data-aos-anchor="[data-aos-id-team]">
              <div className="flex flex-col items-center">
                <img className="rounded-full mb-4" src={require('../images/icons/purple-user-icon.png').default} width="120" height="120" alt="Team member 07" />
                <h4 className="text-xl font-medium mb-1">Daniel</h4>
                <div className="text-gray-500 mb-1">React Web Developer</div>
                <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="#0">@totallyapp.com.au</a>
              </div>
            </div>

            {/* 8th member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-delay="700" data-aos-anchor="[data-aos-id-team]">
              <div className="flex flex-col items-center">
                <img className="rounded-full mb-4" src={require('../images/icons/purple-user-icon.png').default} width="120" height="120" alt="Team member 08" />
                <h4 className="text-xl font-medium mb-1">Santiago</h4>
                <div className="text-gray-500 mb-1">React Web Developer</div>
                <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="mailto: ecotrino@totallyapp.com.au">ecotrino@totallyapp.com.au</a>
              </div>
            </div>

          </div>

        </div>
      </div>
    </section>
  );
}

export default Team;